import { render, staticRenderFns } from "./liveContent.vue?vue&type=template&id=3e4f3b94&scoped=true"
import script from "./liveContent.vue?vue&type=script&lang=js"
export * from "./liveContent.vue?vue&type=script&lang=js"
import style1 from "./liveContent.vue?vue&type=style&index=1&id=3e4f3b94&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e4f3b94",
  null
  
)

export default component.exports